import { isNumber, sortBy } from "lodash";
import React from "react";
import { HTMLAttributes } from "react";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Props } from "recharts/types/component/DefaultTooltipContent";
import clsx from "clsx";

const isNullish = (value: unknown): boolean => {
  return value === null || typeof value === "undefined";
};

const isNumOrStr = (value: unknown): value is number | string =>
  isNumber(value as number) || typeof value === "string";

function defaultFormatter<TValue extends ValueType>(value: TValue) {
  return Array.isArray(value) && isNumOrStr(value[0]) && isNumOrStr(value[1])
    ? (value.join(" ~ ") as TValue)
    : value;
}

const ChartTooltipContent = <TValue extends ValueType, TName extends NameType>(
  props: Props<TValue, TName>,
) => {
  const {
    separator = " : ",
    contentStyle = {},
    itemStyle = {},
    labelStyle = {},
    payload,
    formatter,
    itemSorter,
    wrapperClassName,
    labelClassName,
    label,
    labelFormatter,
    accessibilityLayer = false,
  } = props;

  const renderContent = () => {
    if (payload && payload.length) {
      const listStyle = { padding: 0, margin: 0 };

      const items = (itemSorter ? sortBy(payload, itemSorter) : payload).map(
        (entry, i) => {
          if (entry.type === "none") {
            return null;
          }

          const finalFormatter =
            entry.formatter || formatter || defaultFormatter;
          const { value, name } = entry;
          let finalValue: React.ReactNode = value;
          let finalName: React.ReactNode = name;
          if (
            finalFormatter &&
            finalValue != null &&
            finalName != null &&
            value &&
            name
          ) {
            const formatted = finalFormatter(value, name, entry, i, payload);
            if (Array.isArray(formatted)) {
              [finalValue, finalName] = formatted;
            } else if (formatted != null) {
              finalValue = formatted;
            } else {
              return null;
            }
          }

          const finalItemStyle = {
            display: "block",
            paddingTop: 4,
            paddingBottom: 4,
            color: entry.color || entry.payload.color || "#000",
            ...itemStyle,
          };

          return (
            <li
              className="recharts-tooltip-item"
              key={`tooltip-item-${i}`}
              style={finalItemStyle}
            >
              {isNumOrStr(finalName) ? (
                <span className="recharts-tooltip-item-name">{finalName}</span>
              ) : null}
              {isNumOrStr(finalName) ? (
                <span className="recharts-tooltip-item-separator">
                  {separator}
                </span>
              ) : null}
              <span className="recharts-tooltip-item-value">{finalValue}</span>
              <span className="recharts-tooltip-item-unit">
                {entry.unit || ""}
              </span>
            </li>
          );
        },
      );

      return (
        <ul className="recharts-tooltip-item-list" style={listStyle}>
          {items}
        </ul>
      );
    }

    return null;
  };

  const finalStyle: React.CSSProperties = {
    margin: 0,
    padding: 10,
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    whiteSpace: "nowrap",
    ...contentStyle,
  };
  const finalLabelStyle = {
    margin: 0,
    ...labelStyle,
  };
  const hasLabel = !isNullish(label);
  let finalLabel = hasLabel ? label : "";
  const wrapperCN = clsx("recharts-default-tooltip", wrapperClassName);
  const labelCN = clsx("recharts-tooltip-label", labelClassName);

  if (hasLabel && labelFormatter && payload !== undefined && payload !== null) {
    finalLabel = labelFormatter(label, payload);
  }

  const accessibilityAttributes = accessibilityLayer
    ? ({
        role: "status",
        "aria-live": "assertive",
      } as HTMLAttributes<HTMLDivElement>)
    : {};

  return (
    <div className={wrapperCN} style={finalStyle} {...accessibilityAttributes}>
      <p className={labelCN} style={finalLabelStyle}>
        {React.isValidElement(finalLabel) ? finalLabel : `${finalLabel}`}
      </p>
      {renderContent()}
    </div>
  );
};

export default ChartTooltipContent;
