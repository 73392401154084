import { CircularProgress } from "@nextui-org/react";
import { twMerge } from "tailwind-merge";

const Loading = ({ className }: { className: string }) => {
  return (
    <div
      className={twMerge("w-full flex justify-center items-center", className)}
    >
      <CircularProgress
        color="primary"
        className="text-default-300"
        aria-label="Loading indicator"
      />
    </div>
  );
};

export default Loading;
