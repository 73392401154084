import { Button } from "@nextui-org/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import AuthInput from "../components/auth/Input";
import withHiddable from "../components/hoc/withHiddable";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CustomCheckbox } from "../custom/CustomCheckbox";
import { CreateUserSchema, createUserSchema } from "../schemas/createUser";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { registerRegisterMutation } from "../openapi/@tanstack/react-query.gen";
import { toast } from "react-toastify";

const PasswordInput = withHiddable(AuthInput);

function SignUp() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateUserSchema>({
    resolver: zodResolver(createUserSchema),
  });
  const { mutate: registerUser } = useMutation({
    ...registerRegisterMutation(),
    onSuccess: () => {
      navigate({ to: "/auth/sign-in" });
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        const body = err.response?.data;
        if (body) {
          switch (body.detail) {
            case "REGISTER_USER_ALREADY_EXISTS":
              toast.error("User already exists");
              break;
          }
        }
      }

      toast.error("Something went wrong");
    },
  });

  return (
    <>
      <p className="text-2xl text-[#F8FCFD] font-semibold mb-16">Sign up</p>

      <form
        className="max-w-[300px]"
        onSubmit={handleSubmit((data) => {
          registerUser({ body: data });
        })}
      >
        <AuthInput
          label="Full name"
          placeholder="Your full name"
          {...register("full_name")}
          errorMessage={errors.full_name?.message}
        />
        <AuthInput
          label="Email"
          {...register("email")}
          errorMessage={errors.email?.message}
        />

        <PasswordInput
          label="Password"
          description="Password must contain at least 8 letters and minimum 2 numbers"
          {...register("password")}
          errorMessage={errors.password?.message}
        />

        <CustomCheckbox className="mb-4">
          I agree with the Terms & Conditions and Privacy Policy
        </CustomCheckbox>

        <Button color="primary" className="mb-4 w-full" type="submit">
          Continue
        </Button>
      </form>
    </>
  );
}

export const Route = createFileRoute("/auth/sign-up")({
  component: () => <SignUp />,
});
