import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  Pagination,
} from "@nextui-org/react";
import { useMemo, useState } from "react";

const cellDataToString = (v: unknown): string => {
  if (v instanceof Date) return v.toISOString();
  else if (typeof v === "string") return v;
  else if (typeof v === "number") return v.toString();
  else if (typeof v === "object") return JSON.stringify(v);
  else return "Unknown";
};

type DataRow = { [key: string]: string } & { key: string };

function DataTable(props: {
  data: unknown[][];
  columns: (string | number | null)[];
  pageSize?: number;
}) {
  const pageSize = props.pageSize || 10;
  const [page, setPage] = useState(1);
  const data: DataRow[] = useMemo(
    () =>
      props.data
        // slice the data to the current page
        .slice((page - 1) * pageSize, page * pageSize)
        .map((row, rowIdx) => ({
          ...Object.fromEntries(
            row.map((v, idx) => [idx.toString(), cellDataToString(v)]),
          ),
          key: rowIdx.toString(),
        })),
    [props.data, page, pageSize],
  );

  const column = useMemo(
    () => props.columns.map((v, idx) => ({ label: v, key: idx.toString() })),
    [props.columns],
  );

  return (
    <Table
      aria-label="Table of data"
      classNames={{
        base: "text-small",
        wrapper: "bg-secondary-800",
        th: "bg-secondary-200",
        td: "text-secondary-300",
      }}
      shadow="none"
      bottomContent={
        <div className="flex justify-center w-full">
          <Pagination
            showControls
            isCompact
            showShadow
            total={Math.ceil(props.data.length / pageSize)}
            color="primary"
            page={page}
            onChange={(page) => setPage(page)}
            classNames={{
              prev: "bg-secondary-200 data-[disabled]:text-secondary-400",
              item: "bg-secondary-200 data-[disabled]:text-secondary-300",
              next: "bg-secondary-200 data-[disabled]:text-secondary-400",
            }}
          />
        </div>
      }
    >
      <TableHeader columns={column}>
        {(column) => (
          <TableColumn key={column.key}>
            {cellDataToString(column.label)}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody items={data}>
        {(item) => (
          <TableRow key={item.key}>
            {(columnKey) => (
              <TableCell key={columnKey}>
                {getKeyValue(item, columnKey)}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default DataTable;
