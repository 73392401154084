import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
} from "recharts";
import { Period, PERIODS } from "../../utils";
import BiGraphCard, { ChartFilter } from "../BiGraphCard";
import { useState } from "react";
import { prettyPrintEuros } from "./utils";
import { useQuery } from "@tanstack/react-query";
import Loading from "./Loading";
import { Filters } from "../../openapi";
import { getGrossProfitByPeriodOptions } from "../../openapi/@tanstack/react-query.gen";

function GrossProfitGraph({
  filters,
  tableName,
}: {
  filters: Filters;
  tableName: string;
}) {
  const [period, setPeriod] = useState<Period>("month");

  const { data, isLoading } = useQuery({
    ...getGrossProfitByPeriodOptions({
      query: { table_name: tableName },
      body: { period, filters },
    }),
  });

  if (isLoading) return <Loading className="h-[300px] bg-[#24273F]" />;

  return (
    <BiGraphCard
      title="Gross profit"
      headerComponent={
        <ChartFilter
          label="Period"
          value={period}
          onChange={(v) => setPeriod(v)}
          options={[...PERIODS]}
          labelPlacement="placeholder"
          className="flex-grow-0 w-[200px]"
        />
      }
    >
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={730}
          height={250}
          data={data?.data ?? undefined}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid stroke={"#50536D"} />
          <XAxis
            dataKey="name"
            tick={{ fill: "#C1C3D4" }}
            tickLine={{ stroke: "#C1C3D4" }}
          />
          <YAxis tick={{ fill: "#C1C3D4" }} tickLine={{ stroke: "#C1C3D4" }} />
          <Tooltip formatter={(v: number) => prettyPrintEuros(v)} />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#FD5468"
            name="Gross profit"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </BiGraphCard>
  );
}

export default GrossProfitGraph;
