import { Button, Link } from "@nextui-org/react";
import {
  createFileRoute,
  redirect,
  Link as RouterLink,
  useNavigate,
} from "@tanstack/react-router";
import withHiddable from "../components/hoc/withHiddable";
import AuthInput from "../components/auth/Input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import ErrorMessage from "../components/auth/ErrorMessage";
import { useMutation } from "@tanstack/react-query";
import { authJwtLoginMutation } from "../openapi/@tanstack/react-query.gen";
import { useState } from "react";

const PasswordInput = withHiddable(AuthInput);

const schema = z.object({
  email: z.string().min(1, "Minimum 1 character required"),
  password: z.string().min(1, "Minimum 1 character required"),
});

function SignIn() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
  });
  const [error, setError] = useState<string | null>(null);

  const login = useMutation({
    ...authJwtLoginMutation(),
    onError: (err) => {
      const body = err.response?.data;
      if (err.code === "ERR_NETWORK") {
        setError("Failed to connect to server");
        return;
      } else if (body) {
        if (body.detail === "LOGIN_BAD_CREDENTIALS") {
          setError("Incorrect email or password");
          return;
        }
      }
      setError("Unknown server error");
    },
    onSuccess: async () => {
      await navigate({ to: "/bi", replace: true });
    },
  });

  return (
    <>
      <p className="text-2xl text-[#F8FCFD] font-semibold mb-16">Log in</p>

      <form
        className="max-w-[300px]"
        onSubmit={handleSubmit((data) => {
          login.mutate({
            body: { username: data.email, password: data.password },
          });
        })}
      >
        <AuthInput
          label="Email"
          {...register("email")}
          errorMessage={errors.email?.message}
          isInvalid={errors.root?.message === undefined ? undefined : true}
        />

        <PasswordInput
          label="Password"
          {...register("password")}
          errorMessage={errors.password?.message}
          isInvalid={errors.root?.message === undefined ? undefined : true}
        />

        <div className="mb-16">
          <Link color="primary" size="sm" className="float-right">
            Forgot password?
          </Link>
        </div>

        {error && <ErrorMessage className="mb-4" text={error} />}

        <Button color="primary" className="mb-4 w-full" type="submit">
          Log in
        </Button>
        <div className="flex gap-4 items-center">
          <p className="text-gray-400 text-tiny font-light">
            Don’t have an account?
          </p>
          <Link size="sm" as={RouterLink} to="/auth/sign-up">
            Sign up
          </Link>
        </div>
      </form>
    </>
  );
}

export const Route = createFileRoute("/auth/sign-in")({
  component: () => <SignIn />,
  beforeLoad: ({ context }) => {
    if (context.user) {
      throw redirect({ to: "/bi" });
    }
  },
});
