import { BarChart, LineChart, PieChart, ScatterChart } from "../charts";
import { Chart } from "../../openapi";
import { ResponsiveContainerProps } from "recharts";
export function AutoChart({
  chart,
  width,
  height,
}: { chart: Chart } & Pick<ResponsiveContainerProps, "width" | "height">) {
  switch (chart.chart_type) {
    case "bar":
      return (
        <BarChart
          data={{
            columns: chart.chart_columns,
            data: chart.chart_data.data,
            title: chart.chart_title,
          }}
          width={width}
          height={height}
        />
      );
    case "stacked_bar":
      return (
        <BarChart
          data={{
            columns: chart.chart_columns,
            data: chart.chart_data.data,
            title: chart.chart_title,
          }}
          stacked
          width={width}
          height={height}
        />
      );
    case "grouped_bar":
      return (
        <BarChart
          data={{
            columns: chart.chart_columns,
            data: chart.chart_data.data,
            title: chart.chart_title,
          }}
          stacked={false}
          width={width}
          height={height}
        />
      );
    case "pie":
      return (
        <PieChart
          data={{
            columns: chart.chart_columns,
            data: chart.chart_data.data,
            title: chart.chart_title,
          }}
          width={width}
          height={height}
        />
      );
    case "scatter":
      return (
        <ScatterChart
          data={{
            columns: chart.chart_columns,
            data: chart.chart_data.data,
            title: chart.chart_title,
          }}
          width={width}
          height={height}
        />
      );
    case "line":
      return (
        <LineChart
          data={{
            columns: chart.chart_columns,
            data: chart.chart_data.data,
            title: chart.chart_title,
          }}
          width={width}
          height={height}
        />
      );
    default:
      throw new Error("Invalid chart type");
  }
}
