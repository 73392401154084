import { ChartData } from "../../types";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ScatterChart as RechartsScatterChart,
  Scatter,
  Label,
  ResponsiveContainerProps,
} from "recharts";
import { tooltipOptions } from "./ChartTooltip";

function ScatterChart({
  data: { data, columns },
  width = "100%",
  height = 300,
}: { data: ChartData } & Pick<ResponsiveContainerProps, "width" | "height">) {
  return (
    <ResponsiveContainer
      width={width}
      height={height}
      style={{
        background: "#32364B",
        padding: "0",
        borderRadius: "4px",
      }}
    >
      <RechartsScatterChart
        data={data}
        margin={{ top: 50, right: 30, left: 30, bottom: 30 }}
      >
        <CartesianGrid stroke={"#50536D"} />
        <XAxis
          dataKey={columns[0]}
          tick={{ fill: "#C1C3D4" }}
          tickLine={{ stroke: "#C1C3D4" }}
        >
          <Label
            value={columns[0]}
            position="bottom"
            style={{ textAnchor: "middle" }}
          />
        </XAxis>
        <YAxis tick={{ fill: "#C1C3D4" }} tickLine={{ stroke: "#C1C3D4" }}>
          <Label
            value={columns[1]}
            position="left"
            style={{ textAnchor: "middle" }}
            // textAnchor="end"
            angle={-90}
          />
        </YAxis>
        <Tooltip {...tooltipOptions} />
        {/* <Legend /> */}
        <Scatter dataKey={columns[1]} fill="#1D9DD9" />
      </RechartsScatterChart>
    </ResponsiveContainer>
  );
}

export default ScatterChart;
