import { keepPreviousData, useQuery } from "@tanstack/react-query";
import BiGraphCard, { ChartFilter } from "../BiGraphCard";
import { AutoChart } from "./AutoChart";
import { getAirbusGraphsOptions } from "../../openapi/@tanstack/react-query.gen";
import { AirbusFilters, Datasource } from "../../openapi";
import { CircularProgress, SelectItem } from "@nextui-org/react";
import { Select } from "@nextui-org/react";
import { useState } from "react";

export default function AirbusDashboard({
  tableName,
  tables,
  onSetTableName,
}: {
  tableName: string | null;
  tables: Datasource[];
  onSetTableName: (value: string) => void;
}) {
  const [filters, setFilters] = useState<AirbusFilters>({});

  const { data: charts, isLoading: chartsLoading } = useQuery({
    ...getAirbusGraphsOptions({ query: filters }),
    placeholderData: keepPreviousData,
    retry: false,
  });

  return (
    <div className="flex gap-4 flex-grow">
      <div className="flex flex-col gap-4">
        <Select
          disallowEmptySelection
          variant="flat"
          label="Table"
          color="secondary"
          classNames={{
            label: "text-secondary-400",
            value: "text-secondary-300",
            trigger:
              "bg-transparent data-[hover=true]:bg-transparent border border-secondary-700",
          }}
          labelPlacement="outside"
          selectedKeys={tableName ? [tableName] : undefined}
          onChange={(e) => {
            onSetTableName(e.target.value);
          }}
        >
          {tables.map((v) => (
            <SelectItem key={v.name} value={v.name}>
              {v.name}
            </SelectItem>
          ))}
        </Select>

        {Object.entries(charts?.filters ?? {}).map(([key, value]) => (
          <ChartFilter
            key={key}
            label={key}
            value={key in filters ? filters[key as keyof typeof filters] : null}
            onChange={(v) => {
              setFilters((f) => ({ ...f, [key]: v }));
            }}
            options={value ?? []}
            labelPlacement="outside"
            placeholder="All"
          />
        ))}
      </div>

      {chartsLoading ? (
        <div className="flex flex-col justify-center items-center h-full w-full">
          <CircularProgress />
          <p className="text-secondary-400 text-sm mt-4">Charts loading...</p>
        </div>
      ) : (
        <div className="flex flex-wrap gap-4 items-stretch">
          {charts?.charts?.map((chart) => (
            <div
              className={
                "flex-grow " +
                (chart.chart_type === "pie" ? "basis-[400px]" : "basis-[600px]")
              }
              key={chart.chart_title}
            >
              <BiGraphCard
                title={chart.chart_title}
                className="h-full min-h-[400px]"
              >
                <AutoChart chart={chart} width="100%" height="100%" />
              </BiGraphCard>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
