/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router";

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as AuthImport } from "./routes/auth";
import { Route as LayoutImport } from "./routes/_layout";
import { Route as AuthVerifyImport } from "./routes/auth.verify";
import { Route as AuthSignUpImport } from "./routes/auth.sign-up";
import { Route as AuthSignOutImport } from "./routes/auth.sign-out";
import { Route as AuthSignInImport } from "./routes/auth.sign-in";
import { Route as LayoutUsersImport } from "./routes/_layout.users";
import { Route as LayoutNoOrganizationImport } from "./routes/_layout.no-organization";
import { Route as LayoutInvitedImport } from "./routes/_layout.invited";
import { Route as LayoutBiImport } from "./routes/_layout.bi";

// Create Virtual Routes

const LayoutIndexLazyImport = createFileRoute("/_layout/")();
const LayoutPromptLibraryLazyImport = createFileRoute(
  "/_layout/prompt-library",
)();
const LayoutHistoryLazyImport = createFileRoute("/_layout/history")();
const LayoutBiDistillationLazyImport = createFileRoute(
  "/_layout/bi-distillation",
)();
const LayoutBiFundaEindhovenLazyImport = createFileRoute(
  "/_layout/bi-funda/eindhoven",
)();
const LayoutBiCroweDuoAccountantHonoriaLazyImport = createFileRoute(
  "/_layout/bi-crowe/duo-accountant-honoria",
)();
const LayoutBiCroweBetaalBestandLazyImport = createFileRoute(
  "/_layout/bi-crowe/betaal-bestand",
)();
const LayoutBiCroweBeslissingEnBestandLazyImport = createFileRoute(
  "/_layout/bi-crowe/beslissing-en-bestand",
)();

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: "/auth",
  path: "/auth",
  getParentRoute: () => rootRoute,
} as any);

const LayoutRoute = LayoutImport.update({
  id: "/_layout",
  getParentRoute: () => rootRoute,
} as any);

const LayoutIndexLazyRoute = LayoutIndexLazyImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import("./routes/_layout.index.lazy").then((d) => d.Route),
);

const LayoutPromptLibraryLazyRoute = LayoutPromptLibraryLazyImport.update({
  id: "/prompt-library",
  path: "/prompt-library",
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import("./routes/_layout.prompt-library.lazy").then((d) => d.Route),
);

const LayoutHistoryLazyRoute = LayoutHistoryLazyImport.update({
  id: "/history",
  path: "/history",
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import("./routes/_layout.history.lazy").then((d) => d.Route),
);

const LayoutBiDistillationLazyRoute = LayoutBiDistillationLazyImport.update({
  id: "/bi-distillation",
  path: "/bi-distillation",
  getParentRoute: () => LayoutRoute,
} as any).lazy(() =>
  import("./routes/_layout.bi-distillation.lazy").then((d) => d.Route),
);

const AuthVerifyRoute = AuthVerifyImport.update({
  id: "/verify",
  path: "/verify",
  getParentRoute: () => AuthRoute,
} as any);

const AuthSignUpRoute = AuthSignUpImport.update({
  id: "/sign-up",
  path: "/sign-up",
  getParentRoute: () => AuthRoute,
} as any);

const AuthSignOutRoute = AuthSignOutImport.update({
  id: "/sign-out",
  path: "/sign-out",
  getParentRoute: () => AuthRoute,
} as any);

const AuthSignInRoute = AuthSignInImport.update({
  id: "/sign-in",
  path: "/sign-in",
  getParentRoute: () => AuthRoute,
} as any);

const LayoutUsersRoute = LayoutUsersImport.update({
  id: "/users",
  path: "/users",
  getParentRoute: () => LayoutRoute,
} as any);

const LayoutNoOrganizationRoute = LayoutNoOrganizationImport.update({
  id: "/no-organization",
  path: "/no-organization",
  getParentRoute: () => LayoutRoute,
} as any);

const LayoutInvitedRoute = LayoutInvitedImport.update({
  id: "/invited",
  path: "/invited",
  getParentRoute: () => LayoutRoute,
} as any);

const LayoutBiRoute = LayoutBiImport.update({
  id: "/bi",
  path: "/bi",
  getParentRoute: () => LayoutRoute,
} as any);

const LayoutBiFundaEindhovenLazyRoute = LayoutBiFundaEindhovenLazyImport.update(
  {
    id: "/bi-funda/eindhoven",
    path: "/bi-funda/eindhoven",
    getParentRoute: () => LayoutRoute,
  } as any,
).lazy(() =>
  import("./routes/_layout.bi-funda.eindhoven.lazy").then((d) => d.Route),
);

const LayoutBiCroweDuoAccountantHonoriaLazyRoute =
  LayoutBiCroweDuoAccountantHonoriaLazyImport.update({
    id: "/bi-crowe/duo-accountant-honoria",
    path: "/bi-crowe/duo-accountant-honoria",
    getParentRoute: () => LayoutRoute,
  } as any).lazy(() =>
    import("./routes/_layout.bi-crowe.duo-accountant-honoria.lazy").then(
      (d) => d.Route,
    ),
  );

const LayoutBiCroweBetaalBestandLazyRoute =
  LayoutBiCroweBetaalBestandLazyImport.update({
    id: "/bi-crowe/betaal-bestand",
    path: "/bi-crowe/betaal-bestand",
    getParentRoute: () => LayoutRoute,
  } as any).lazy(() =>
    import("./routes/_layout.bi-crowe.betaal-bestand.lazy").then(
      (d) => d.Route,
    ),
  );

const LayoutBiCroweBeslissingEnBestandLazyRoute =
  LayoutBiCroweBeslissingEnBestandLazyImport.update({
    id: "/bi-crowe/beslissing-en-bestand",
    path: "/bi-crowe/beslissing-en-bestand",
    getParentRoute: () => LayoutRoute,
  } as any).lazy(() =>
    import("./routes/_layout.bi-crowe.beslissing-en-bestand.lazy").then(
      (d) => d.Route,
    ),
  );

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/_layout": {
      id: "/_layout";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof LayoutImport;
      parentRoute: typeof rootRoute;
    };
    "/auth": {
      id: "/auth";
      path: "/auth";
      fullPath: "/auth";
      preLoaderRoute: typeof AuthImport;
      parentRoute: typeof rootRoute;
    };
    "/_layout/bi": {
      id: "/_layout/bi";
      path: "/bi";
      fullPath: "/bi";
      preLoaderRoute: typeof LayoutBiImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/invited": {
      id: "/_layout/invited";
      path: "/invited";
      fullPath: "/invited";
      preLoaderRoute: typeof LayoutInvitedImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/no-organization": {
      id: "/_layout/no-organization";
      path: "/no-organization";
      fullPath: "/no-organization";
      preLoaderRoute: typeof LayoutNoOrganizationImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/users": {
      id: "/_layout/users";
      path: "/users";
      fullPath: "/users";
      preLoaderRoute: typeof LayoutUsersImport;
      parentRoute: typeof LayoutImport;
    };
    "/auth/sign-in": {
      id: "/auth/sign-in";
      path: "/sign-in";
      fullPath: "/auth/sign-in";
      preLoaderRoute: typeof AuthSignInImport;
      parentRoute: typeof AuthImport;
    };
    "/auth/sign-out": {
      id: "/auth/sign-out";
      path: "/sign-out";
      fullPath: "/auth/sign-out";
      preLoaderRoute: typeof AuthSignOutImport;
      parentRoute: typeof AuthImport;
    };
    "/auth/sign-up": {
      id: "/auth/sign-up";
      path: "/sign-up";
      fullPath: "/auth/sign-up";
      preLoaderRoute: typeof AuthSignUpImport;
      parentRoute: typeof AuthImport;
    };
    "/auth/verify": {
      id: "/auth/verify";
      path: "/verify";
      fullPath: "/auth/verify";
      preLoaderRoute: typeof AuthVerifyImport;
      parentRoute: typeof AuthImport;
    };
    "/_layout/bi-distillation": {
      id: "/_layout/bi-distillation";
      path: "/bi-distillation";
      fullPath: "/bi-distillation";
      preLoaderRoute: typeof LayoutBiDistillationLazyImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/history": {
      id: "/_layout/history";
      path: "/history";
      fullPath: "/history";
      preLoaderRoute: typeof LayoutHistoryLazyImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/prompt-library": {
      id: "/_layout/prompt-library";
      path: "/prompt-library";
      fullPath: "/prompt-library";
      preLoaderRoute: typeof LayoutPromptLibraryLazyImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/": {
      id: "/_layout/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof LayoutIndexLazyImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/bi-crowe/beslissing-en-bestand": {
      id: "/_layout/bi-crowe/beslissing-en-bestand";
      path: "/bi-crowe/beslissing-en-bestand";
      fullPath: "/bi-crowe/beslissing-en-bestand";
      preLoaderRoute: typeof LayoutBiCroweBeslissingEnBestandLazyImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/bi-crowe/betaal-bestand": {
      id: "/_layout/bi-crowe/betaal-bestand";
      path: "/bi-crowe/betaal-bestand";
      fullPath: "/bi-crowe/betaal-bestand";
      preLoaderRoute: typeof LayoutBiCroweBetaalBestandLazyImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/bi-crowe/duo-accountant-honoria": {
      id: "/_layout/bi-crowe/duo-accountant-honoria";
      path: "/bi-crowe/duo-accountant-honoria";
      fullPath: "/bi-crowe/duo-accountant-honoria";
      preLoaderRoute: typeof LayoutBiCroweDuoAccountantHonoriaLazyImport;
      parentRoute: typeof LayoutImport;
    };
    "/_layout/bi-funda/eindhoven": {
      id: "/_layout/bi-funda/eindhoven";
      path: "/bi-funda/eindhoven";
      fullPath: "/bi-funda/eindhoven";
      preLoaderRoute: typeof LayoutBiFundaEindhovenLazyImport;
      parentRoute: typeof LayoutImport;
    };
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutBiRoute: typeof LayoutBiRoute;
  LayoutInvitedRoute: typeof LayoutInvitedRoute;
  LayoutNoOrganizationRoute: typeof LayoutNoOrganizationRoute;
  LayoutUsersRoute: typeof LayoutUsersRoute;
  LayoutBiDistillationLazyRoute: typeof LayoutBiDistillationLazyRoute;
  LayoutHistoryLazyRoute: typeof LayoutHistoryLazyRoute;
  LayoutPromptLibraryLazyRoute: typeof LayoutPromptLibraryLazyRoute;
  LayoutIndexLazyRoute: typeof LayoutIndexLazyRoute;
  LayoutBiCroweBeslissingEnBestandLazyRoute: typeof LayoutBiCroweBeslissingEnBestandLazyRoute;
  LayoutBiCroweBetaalBestandLazyRoute: typeof LayoutBiCroweBetaalBestandLazyRoute;
  LayoutBiCroweDuoAccountantHonoriaLazyRoute: typeof LayoutBiCroweDuoAccountantHonoriaLazyRoute;
  LayoutBiFundaEindhovenLazyRoute: typeof LayoutBiFundaEindhovenLazyRoute;
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutBiRoute: LayoutBiRoute,
  LayoutInvitedRoute: LayoutInvitedRoute,
  LayoutNoOrganizationRoute: LayoutNoOrganizationRoute,
  LayoutUsersRoute: LayoutUsersRoute,
  LayoutBiDistillationLazyRoute: LayoutBiDistillationLazyRoute,
  LayoutHistoryLazyRoute: LayoutHistoryLazyRoute,
  LayoutPromptLibraryLazyRoute: LayoutPromptLibraryLazyRoute,
  LayoutIndexLazyRoute: LayoutIndexLazyRoute,
  LayoutBiCroweBeslissingEnBestandLazyRoute:
    LayoutBiCroweBeslissingEnBestandLazyRoute,
  LayoutBiCroweBetaalBestandLazyRoute: LayoutBiCroweBetaalBestandLazyRoute,
  LayoutBiCroweDuoAccountantHonoriaLazyRoute:
    LayoutBiCroweDuoAccountantHonoriaLazyRoute,
  LayoutBiFundaEindhovenLazyRoute: LayoutBiFundaEindhovenLazyRoute,
};

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren);

interface AuthRouteChildren {
  AuthSignInRoute: typeof AuthSignInRoute;
  AuthSignOutRoute: typeof AuthSignOutRoute;
  AuthSignUpRoute: typeof AuthSignUpRoute;
  AuthVerifyRoute: typeof AuthVerifyRoute;
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthSignInRoute: AuthSignInRoute,
  AuthSignOutRoute: AuthSignOutRoute,
  AuthSignUpRoute: AuthSignUpRoute,
  AuthVerifyRoute: AuthVerifyRoute,
};

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren);

export interface FileRoutesByFullPath {
  "": typeof LayoutRouteWithChildren;
  "/auth": typeof AuthRouteWithChildren;
  "/bi": typeof LayoutBiRoute;
  "/invited": typeof LayoutInvitedRoute;
  "/no-organization": typeof LayoutNoOrganizationRoute;
  "/users": typeof LayoutUsersRoute;
  "/auth/sign-in": typeof AuthSignInRoute;
  "/auth/sign-out": typeof AuthSignOutRoute;
  "/auth/sign-up": typeof AuthSignUpRoute;
  "/auth/verify": typeof AuthVerifyRoute;
  "/bi-distillation": typeof LayoutBiDistillationLazyRoute;
  "/history": typeof LayoutHistoryLazyRoute;
  "/prompt-library": typeof LayoutPromptLibraryLazyRoute;
  "/": typeof LayoutIndexLazyRoute;
  "/bi-crowe/beslissing-en-bestand": typeof LayoutBiCroweBeslissingEnBestandLazyRoute;
  "/bi-crowe/betaal-bestand": typeof LayoutBiCroweBetaalBestandLazyRoute;
  "/bi-crowe/duo-accountant-honoria": typeof LayoutBiCroweDuoAccountantHonoriaLazyRoute;
  "/bi-funda/eindhoven": typeof LayoutBiFundaEindhovenLazyRoute;
}

export interface FileRoutesByTo {
  "/auth": typeof AuthRouteWithChildren;
  "/bi": typeof LayoutBiRoute;
  "/invited": typeof LayoutInvitedRoute;
  "/no-organization": typeof LayoutNoOrganizationRoute;
  "/users": typeof LayoutUsersRoute;
  "/auth/sign-in": typeof AuthSignInRoute;
  "/auth/sign-out": typeof AuthSignOutRoute;
  "/auth/sign-up": typeof AuthSignUpRoute;
  "/auth/verify": typeof AuthVerifyRoute;
  "/bi-distillation": typeof LayoutBiDistillationLazyRoute;
  "/history": typeof LayoutHistoryLazyRoute;
  "/prompt-library": typeof LayoutPromptLibraryLazyRoute;
  "/": typeof LayoutIndexLazyRoute;
  "/bi-crowe/beslissing-en-bestand": typeof LayoutBiCroweBeslissingEnBestandLazyRoute;
  "/bi-crowe/betaal-bestand": typeof LayoutBiCroweBetaalBestandLazyRoute;
  "/bi-crowe/duo-accountant-honoria": typeof LayoutBiCroweDuoAccountantHonoriaLazyRoute;
  "/bi-funda/eindhoven": typeof LayoutBiFundaEindhovenLazyRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/_layout": typeof LayoutRouteWithChildren;
  "/auth": typeof AuthRouteWithChildren;
  "/_layout/bi": typeof LayoutBiRoute;
  "/_layout/invited": typeof LayoutInvitedRoute;
  "/_layout/no-organization": typeof LayoutNoOrganizationRoute;
  "/_layout/users": typeof LayoutUsersRoute;
  "/auth/sign-in": typeof AuthSignInRoute;
  "/auth/sign-out": typeof AuthSignOutRoute;
  "/auth/sign-up": typeof AuthSignUpRoute;
  "/auth/verify": typeof AuthVerifyRoute;
  "/_layout/bi-distillation": typeof LayoutBiDistillationLazyRoute;
  "/_layout/history": typeof LayoutHistoryLazyRoute;
  "/_layout/prompt-library": typeof LayoutPromptLibraryLazyRoute;
  "/_layout/": typeof LayoutIndexLazyRoute;
  "/_layout/bi-crowe/beslissing-en-bestand": typeof LayoutBiCroweBeslissingEnBestandLazyRoute;
  "/_layout/bi-crowe/betaal-bestand": typeof LayoutBiCroweBetaalBestandLazyRoute;
  "/_layout/bi-crowe/duo-accountant-honoria": typeof LayoutBiCroweDuoAccountantHonoriaLazyRoute;
  "/_layout/bi-funda/eindhoven": typeof LayoutBiFundaEindhovenLazyRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ""
    | "/auth"
    | "/bi"
    | "/invited"
    | "/no-organization"
    | "/users"
    | "/auth/sign-in"
    | "/auth/sign-out"
    | "/auth/sign-up"
    | "/auth/verify"
    | "/bi-distillation"
    | "/history"
    | "/prompt-library"
    | "/"
    | "/bi-crowe/beslissing-en-bestand"
    | "/bi-crowe/betaal-bestand"
    | "/bi-crowe/duo-accountant-honoria"
    | "/bi-funda/eindhoven";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/auth"
    | "/bi"
    | "/invited"
    | "/no-organization"
    | "/users"
    | "/auth/sign-in"
    | "/auth/sign-out"
    | "/auth/sign-up"
    | "/auth/verify"
    | "/bi-distillation"
    | "/history"
    | "/prompt-library"
    | "/"
    | "/bi-crowe/beslissing-en-bestand"
    | "/bi-crowe/betaal-bestand"
    | "/bi-crowe/duo-accountant-honoria"
    | "/bi-funda/eindhoven";
  id:
    | "__root__"
    | "/_layout"
    | "/auth"
    | "/_layout/bi"
    | "/_layout/invited"
    | "/_layout/no-organization"
    | "/_layout/users"
    | "/auth/sign-in"
    | "/auth/sign-out"
    | "/auth/sign-up"
    | "/auth/verify"
    | "/_layout/bi-distillation"
    | "/_layout/history"
    | "/_layout/prompt-library"
    | "/_layout/"
    | "/_layout/bi-crowe/beslissing-en-bestand"
    | "/_layout/bi-crowe/betaal-bestand"
    | "/_layout/bi-crowe/duo-accountant-honoria"
    | "/_layout/bi-funda/eindhoven";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren;
  AuthRoute: typeof AuthRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/auth"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/bi",
        "/_layout/invited",
        "/_layout/no-organization",
        "/_layout/users",
        "/_layout/bi-distillation",
        "/_layout/history",
        "/_layout/prompt-library",
        "/_layout/",
        "/_layout/bi-crowe/beslissing-en-bestand",
        "/_layout/bi-crowe/betaal-bestand",
        "/_layout/bi-crowe/duo-accountant-honoria",
        "/_layout/bi-funda/eindhoven"
      ]
    },
    "/auth": {
      "filePath": "auth.tsx",
      "children": [
        "/auth/sign-in",
        "/auth/sign-out",
        "/auth/sign-up",
        "/auth/verify"
      ]
    },
    "/_layout/bi": {
      "filePath": "_layout.bi.tsx",
      "parent": "/_layout"
    },
    "/_layout/invited": {
      "filePath": "_layout.invited.tsx",
      "parent": "/_layout"
    },
    "/_layout/no-organization": {
      "filePath": "_layout.no-organization.tsx",
      "parent": "/_layout"
    },
    "/_layout/users": {
      "filePath": "_layout.users.tsx",
      "parent": "/_layout"
    },
    "/auth/sign-in": {
      "filePath": "auth.sign-in.tsx",
      "parent": "/auth"
    },
    "/auth/sign-out": {
      "filePath": "auth.sign-out.tsx",
      "parent": "/auth"
    },
    "/auth/sign-up": {
      "filePath": "auth.sign-up.tsx",
      "parent": "/auth"
    },
    "/auth/verify": {
      "filePath": "auth.verify.tsx",
      "parent": "/auth"
    },
    "/_layout/bi-distillation": {
      "filePath": "_layout.bi-distillation.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/history": {
      "filePath": "_layout.history.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/prompt-library": {
      "filePath": "_layout.prompt-library.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/": {
      "filePath": "_layout.index.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/bi-crowe/beslissing-en-bestand": {
      "filePath": "_layout.bi-crowe.beslissing-en-bestand.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/bi-crowe/betaal-bestand": {
      "filePath": "_layout.bi-crowe.betaal-bestand.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/bi-crowe/duo-accountant-honoria": {
      "filePath": "_layout.bi-crowe.duo-accountant-honoria.lazy.tsx",
      "parent": "/_layout"
    },
    "/_layout/bi-funda/eindhoven": {
      "filePath": "_layout.bi-funda.eindhoven.lazy.tsx",
      "parent": "/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
