import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import BiGraphCard, { ChartFilter } from "../BiGraphCard";
import { PERIODS, Period } from "../../utils";
import { useState } from "react";
import { Filters } from "../../openapi";
import { prettyPrintEuros } from "./utils";
import { useQuery } from "@tanstack/react-query";
import Loading from "./Loading";
import { getRevenuePerPeriodOptions } from "../../openapi/@tanstack/react-query.gen";

function RevenuePerPeriod({
  filters,
  tableName,
}: {
  filters: Filters;
  tableName: string;
}) {
  const [period, setPeriod] = useState<Period>("month");
  const { data, isLoading } = useQuery({
    ...getRevenuePerPeriodOptions({
      query: { table_name: tableName },
      body: { filters, period },
    }),
  });

  if (isLoading) return <Loading className="h-[300px] bg-[#24273F]" />;

  return (
    <BiGraphCard
      title="Total revenue"
      headerComponent={
        <ChartFilter
          label="Period"
          value={period}
          onChange={setPeriod}
          options={[...PERIODS]}
          labelPlacement="placeholder"
          className="flex-grow-0 w-[200px]"
        />
      }
    >
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data?.data}
          margin={{ top: 5, right: 30, left: 35, bottom: 30 }}
        >
          <CartesianGrid stroke={"#50536D"} />
          <XAxis
            dataKey="period"
            tick={{ fill: "#C1C3D4" }}
            tickLine={{ stroke: "#C1C3D4" }}
            label={{
              value: "Date",
              position: "bottom",
              offset: 10,
              fill: "#C1C3D4",
            }}
          />
          <YAxis
            tick={{ fill: "#C1C3D4" }}
            tickLine={{ stroke: "#C1C3D4" }}
            label={{
              value: "Total revenue",
              angle: -90,
              position: "left",
              align: "center",
              offset: 25,
              style: { textAnchor: "middle" },
              fill: "#C1C3D4",
            }}
          />
          <Tooltip formatter={prettyPrintEuros} />
          {/* <Legend /> */}
          <Bar dataKey="total_revenue" fill="#1D9DD9" name="Total Revenue" />
        </BarChart>
      </ResponsiveContainer>
    </BiGraphCard>
  );
}

export default RevenuePerPeriod;
