import { Select, SelectItem, ScrollShadow } from "@nextui-org/react";
import BiGraphCard, { ChartFilter } from "../BiGraphCard";
import DataTable from "../DataTable";
import KpiStats from "../KpiStats";
import AnomalyGraph, { AnomalyGraphProps } from "./AnomalyGraph";
import GrossProfitGraph from "./GrossProfitGraph";
import RegressionGraph from "./RegressionGraph";
import RevenuePerPeriod from "./RevenuePerPeriodGraph";

import TotalGrossProfitGraph from "./TotalGrossProfitGraph";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { Datasource, Filters } from "../../openapi";
import { useQuery } from "@tanstack/react-query";
import Loading from "./Loading";
import {
  getFilterOptionsOptions,
  getKpiOptions,
  getPreviewOptions,
  getSalesForecastOptions,
  runRegressionOptions,
} from "../../openapi/@tanstack/react-query.gen";
import SalesForecastGraph from "./SalesForecastGraph";

export type BiKpis = Record<string, number | string>;
export type Data = Record<string, number>[];

function MainDashboard({
  tableName,
  tables,
  onSetTableName,
}: {
  tableName: string | null | undefined;
  tables: Datasource[];
  onSetTableName: (value: string) => void;
}) {
  const [filters, setFilters] = useState<Filters>({
    Region: [],
    Month: [],
    Year: [],
    Quarter: [],
  });

  const filterOptions = useQuery({
    ...getFilterOptionsOptions({ query: { table_name: tableName! } }),
    enabled: !!tableName,
  });

  const kpis = useQuery({
    ...getKpiOptions({
      query: { table_name: tableName! },
      body: { filters },
    }),
    enabled: !!tableName,
  });

  const tableData = useQuery({
    ...getPreviewOptions({
      path: { datasource_name: tableName! },
      body: { filters },
    }),
    enabled: !!tableName,
  });

  const [anomaly] = useState<(AnomalyGraphProps & { name: string }) | null>(
    null,
  );

  const salesForecast = useQuery({
    ...getSalesForecastOptions({
      query: { table_name: tableName! },
      body: {
        sales_column: "Totale Omzet",
        date_column: "Date",
        forecast_periods: 6,
      },
    }),
    enabled: !!tableName,
    select: (data) => ({
      name: data.plot_settings.title,
      xLabel: data.plot_settings.x_label,
      yLabel: data.plot_settings.y_label,
      data: data.data.map((v) => ({
        forecast: v.forecast,
        dateEpoch: dayjs(v.forecast_date).unix(),
        bound: [v.lower_bound, v.upper_bound],
      })),
    }),
  });

  const regression = useQuery({
    ...runRegressionOptions({
      query: { table_name: tableName! },
      body: {
        independent_vars: ["Totale Reclame Kosten"],
        dependent_var: "Totale Omzet",
        filters: {},
      },
    }),
    enabled: !!tableName,
    select: (data) => ({
      name: data.plot_settings.title,
      xLabel: data.plot_settings.x_label,
      yLabel: data.plot_settings.y_label,
      data: [
        ...data.data.independent_variable.map((y, i) => ({
          y,
          residuals: data.data.residuals[i],
        })),
        ...data.data.independent_variable.map((y, i) => ({
          y,
          predicted: data.data.predicted_values[i],
        })),
      ],
      legend: `R2: ${data.data.rsquared.toFixed(2)}`,
    }),
  });

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      Region: ((filterOptions.data?.available_regions as string[]) ?? []).slice(
        0,
        3,
      ),
    }));
  }, [filterOptions.data?.available_regions]);

  return (
    <>
      <div className="flex gap-6 flex-wrap justify-between">
        {kpis.isLoading && <Loading className="h-10" />}
        {kpis.isSuccess &&
          Object.keys(kpis.data.kpis ?? {}).map((name) => (
            <KpiStats
              key={name}
              value={kpis.data.kpis[name]}
              name={name}
              color="#1D9DD9"
            />
          ))}
      </div>

      <div className="flex gap-8 flex-col">
        <div className="flex gap-4">
          <Select
            disallowEmptySelection
            variant="flat"
            label="Table"
            color="secondary"
            classNames={{
              label: "text-secondary-400",
              value: "text-secondary-300",
              trigger:
                "bg-transparent data-[hover=true]:bg-transparent border border-secondary-700",
            }}
            labelPlacement="outside"
            selectedKeys={tableName ? [tableName] : undefined}
            onChange={(e) => {
              onSetTableName(e.target.value);
            }}
          >
            {tables.map((v) => (
              <SelectItem key={v.name} value={v.name}>
                {v.name}
              </SelectItem>
            ))}
          </Select>

          <ChartFilter
            label="Year"
            value={filters.Year}
            onChange={(v) => setFilters((f) => ({ ...f, Year: v }))}
            options={(filterOptions.data?.available_years ?? []) as number[]}
            labelPlacement="outside"
            placeholder="All"
          />

          <ChartFilter
            label="Quarter"
            value={filters.Quarter}
            onChange={(v) => setFilters((f) => ({ ...f, Quarter: v }))}
            options={(filterOptions.data?.available_quaters ?? []) as number[]}
            labelPlacement="outside"
            placeholder="All"
          />

          <ChartFilter
            label="Month"
            value={filters.Month}
            onChange={(v) => setFilters((f) => ({ ...f, Month: v }))}
            options={(filterOptions.data?.available_months ?? []) as number[]}
            labelPlacement="outside"
            placeholder="All"
          />

          <ChartFilter
            label="Regions"
            value={filters.Region ?? []}
            onChange={(v) => setFilters((f) => ({ ...f, Region: v }))}
            options={(filterOptions.data?.available_regions ?? []) as string[]}
            labelPlacement="outside"
            className="flex-grow-0 min-w-[350px]"
          />
        </div>

        <ScrollShadow>
          <div className="grid grid-cols-2 gap-4">
            {anomaly && (
              <BiGraphCard title={anomaly.name} className="col-span-2">
                <AnomalyGraph
                  height={300}
                  xLabel={anomaly.xLabel}
                  yLabel={anomaly.yLabel}
                  data={anomaly.data}
                />
              </BiGraphCard>
            )}

            {salesForecast.isLoading && (
              <Loading className="h-[440px] bg-[#24273F]" />
            )}

            {salesForecast.isSuccess && (
              <BiGraphCard title={salesForecast.data.name}>
                <SalesForecastGraph
                  height={300}
                  xLabel={salesForecast.data.xLabel}
                  yLabel={salesForecast.data.yLabel}
                  data={salesForecast.data.data}
                />
              </BiGraphCard>
            )}

            {regression.isLoading && (
              <Loading className="h-[440px] bg-[#24273F]" />
            )}

            {regression.isSuccess && (
              <BiGraphCard title={regression.data.name}>
                <RegressionGraph
                  height={300}
                  xLabel={regression.data.xLabel}
                  yLabel={regression.data.yLabel}
                  data={regression.data.data}
                  legend={regression.data.legend}
                />
              </BiGraphCard>
            )}

            {tableName && (
              <>
                <GrossProfitGraph tableName={tableName} filters={filters} />
                <RevenuePerPeriod tableName={tableName} filters={filters} />
                <TotalGrossProfitGraph
                  tableName={tableName}
                  filters={filters}
                />
              </>
            )}

            {tableData.isLoading && (
              <Loading className="h-[500px] col-span-2 bg-[#24273F]" />
            )}

            {tableData.data && (
              <BiGraphCard title="Data Preview" className="col-span-2">
                <DataTable
                  columns={tableData.data.columns}
                  data={tableData.data.data}
                />
              </BiGraphCard>
            )}
          </div>
        </ScrollShadow>
      </div>
    </>
  );
}

export default MainDashboard;
