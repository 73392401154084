import { createFileRoute } from "@tanstack/react-router";
import PageHeader from "../components/basic/PageHeader";
import { useEffect } from "react";
import ShellDashboard from "../components/bi/ShellDashboard";
import MainDashboard from "../components/bi/MainDashboard";
import { useQuery } from "@tanstack/react-query";
import { getDatasourcesOptions } from "../openapi/@tanstack/react-query.gen";
import AirbusDashboard from "../components/bi/AirbusDashboard";
import { z } from "zod";

export type BiKpis = Record<string, number | string>;
export type Data = { name: string; value: number }[];

const searchParamsSchema = z.object({
  t: z.string().optional(),
});

function BiDashboard() {
  const { t: tableName } = Route.useSearch();
  const navigate = Route.useNavigate();

  const tables = useQuery({
    ...getDatasourcesOptions(),
    select: (data) =>
      data.filter(
        (table) =>
          table.name.includes("corrosion") ||
          table.name.toLowerCase().includes("kitchen") ||
          table.name.toLowerCase().includes("airbus"),
      ),
  });

  const onSetTableName = (tableName: string) => {
    navigate({
      search: {
        t: tableName,
      },
    });
  };

  useEffect(() => {
    if (tableName == undefined && tables.data && tables.data.length >= 1) {
      navigate({
        search: {
          t: tables.data[0].name,
        },
      });
    }
  }, [navigate, tableName, tables.data]);

  return (
    <div className="flex flex-col gap-8 items-stretch h-full">
      <PageHeader title="Overview" />
      {tableName?.includes("corrosion") ? (
        <ShellDashboard
          tableName={tableName}
          tables={tables.data ?? []}
          onSetTableName={onSetTableName}
        />
      ) : tableName?.toLowerCase().includes("airbus") ? (
        <AirbusDashboard
          tableName={tableName}
          tables={tables.data ?? []}
          onSetTableName={onSetTableName}
        />
      ) : (
        <MainDashboard
          tableName={tableName}
          tables={tables.data ?? []}
          onSetTableName={onSetTableName}
        />
      )}
    </div>
  );
}

export const Route = createFileRoute("/_layout/bi")({
  component: () => <BiDashboard />,
  validateSearch: (searchParams) => searchParamsSchema.parse(searchParams),
});
