import {
  Select,
  SelectItem,
  ScrollShadow,
  CircularProgress,
} from "@nextui-org/react";
import { useState, useEffect } from "react";
import {
  GeneralFilters,
  Datasource,
  getFilterOptions,
  RunRegressionResponse,
} from "../../openapi";
import BiGraphCard, { ChartFilter } from "../BiGraphCard";
import DataTable from "../DataTable";
import KpiStats from "../KpiStats";
import RegressionGraph from "./RegressionGraph";
import { useQueries, useQuery } from "@tanstack/react-query";
import {
  getPreviewOptions,
  getShellKpisOptions,
  runRegressionOptions,
} from "../../openapi/@tanstack/react-query.gen";

export type BiKpis = Record<string, number | string>;
export type Data = Record<string, number>[];

function ShellDashboard({
  tableName,
  tables,
  onSetTableName,
}: {
  tableName: string | null;
  tables: Datasource[];
  onSetTableName: (value: string) => void;
}) {
  const [filters, setFilters] = useState<GeneralFilters>({});
  const [filterOptions, setFilterOptions] = useState<GeneralFilters>({});

  const kpis = useQuery({
    ...getShellKpisOptions({
      query: { table_name: tableName! },
      body: {
        filters,
      },
    }),
    enabled: !!tableName,
  });

  const regressions = useQueries({
    queries: [
      "Temperature",
      "CO2 Pressure",
      "Shear Stress",
      "Flow Velocity",
    ].map((independent) => ({
      ...runRegressionOptions({
        query: { table_name: tableName! },
        body: {
          independent_vars: [independent],
          dependent_var: "Corrosion Rate",
          filters,
        },
      }),
      enabled: !!tableName,
      select: (data: RunRegressionResponse) => ({
        name: "Corrosion Rate & " + data.plot_settings.x_label,
        xLabel: data.plot_settings.x_label,
        yLabel: data.plot_settings.y_label,
        data: [
          ...data.data.independent_variable.map((y, i) => ({
            y,
            residuals: data.data.residuals[i],
          })),
          ...data.data.independent_variable.map((y, i) => ({
            y,
            predicted: data.data.predicted_values[i],
          })),
        ],
        legend: `R2: ${data.data.rsquared.toFixed(2)}`,
      }),
    })),
  });

  const tableData = useQuery({
    ...getPreviewOptions({
      path: { datasource_name: tableName! },
      body: { filters },
    }),
    enabled: !!tableName,
  });

  useEffect(() => {
    if (tableName) {
      getFilterOptions({ query: { table_name: tableName } })
        .then((v) => {
          if (v.data) {
            setFilterOptions(v.data);
            setFilters(
              Object.fromEntries(Object.keys(v.data).map((k) => [k, []])),
            );
          }
        })
        .catch(console.error);
    }
  }, [tableName]);

  useEffect(() => {
    if (!tableName) {
      setFilterOptions({
        regions: [],
        months: [],
        years: [],
        quarters: [],
      });
    }
  }, [tableName, filters]);

  if (
    kpis.isLoading ||
    tableData.isLoading ||
    regressions.some((r) => r.isLoading)
  ) {
    return (
      <div className="h-[500px] w-full flex justify-center items-center">
        <CircularProgress
          color="primary"
          className="text-default-300"
          label="Loading..."
        />
      </div>
    );
  }

  return (
    <>
      {kpis.data && (
        <div className="flex gap-6 flex-wrap justify-between">
          {kpis.isSuccess &&
            Object.entries(kpis.data.kpis).map(([name, kpi]) => (
              <KpiStats key={name} value={kpi} name={name} color="#1D9DD9" />
            ))}
        </div>
      )}

      <div className="flex gap-8">
        <div className="flex gap-4 flex-col flex-grow-0">
          <Select
            className="w-56"
            disallowEmptySelection
            variant="flat"
            label="Table"
            color="secondary"
            classNames={{
              label: "text-secondary-400",
              value: "text-secondary-300",
              trigger:
                "bg-transparent data-[hover=true]:bg-transparent border border-secondary-700",
            }}
            labelPlacement="outside"
            selectedKeys={tableName ? [tableName] : undefined}
            onChange={(e) => {
              onSetTableName(e.target.value);
            }}
          >
            {tables.map((v) => (
              <SelectItem key={v.name} value={v.name}>
                {v.name}
              </SelectItem>
            ))}
          </Select>

          {Object.keys(filterOptions).map((f) => (
            <ChartFilter
              className="w-56"
              key={f}
              label={f}
              value={filters[f]}
              onChange={(v) =>
                setFilters((existing) => ({ ...existing, [f]: v }))
              }
              options={filterOptions[f]}
              labelPlacement="outside"
              placeholder="All"
            />
          ))}
        </div>

        <ScrollShadow>
          <div className="grid grid-cols-2 gap-4 flex-auto">
            {regressions.map(({ data, isSuccess }) =>
              isSuccess === false ? (
                <></>
              ) : (
                <BiGraphCard title={data.name} key={data.name}>
                  <RegressionGraph
                    xLabel={data.xLabel}
                    yLabel={data.yLabel}
                    data={data.data}
                    legend={data.legend}
                  />
                </BiGraphCard>
              ),
            )}

            {tableData.isSuccess && (
              <BiGraphCard title="Data Preview" className="col-span-2">
                <DataTable
                  columns={tableData.data.columns}
                  data={tableData.data.data}
                />
              </BiGraphCard>
            )}
          </div>
        </ScrollShadow>
      </div>
    </>
  );
}

export default ShellDashboard;
