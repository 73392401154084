import { ChartData } from "../../types";
import {
  ResponsiveContainer,
  PieChart as RechartsPieChart,
  Legend,
  Tooltip,
  Pie,
  Cell,
  ResponsiveContainerProps,
} from "recharts";
import { GRAPH_COLOR_PALETTE } from "../../utils";
import { tooltipOptions } from "./ChartTooltip";

function PieChart({
  data: { data, columns },
  width = "100%",
  height = 250,
}: { data: ChartData } & Pick<ResponsiveContainerProps, "width" | "height">) {
  const dataWithColors = data.map((entry, index) => ({
    ...entry,
    color: GRAPH_COLOR_PALETTE[index % GRAPH_COLOR_PALETTE.length],
  }));

  const manyLabels = data.length > 5;
  return (
    <ResponsiveContainer
      width={width}
      height={height}
      style={{
        background: "#32364B",
        borderRadius: "4px",
      }}
    >
      <RechartsPieChart
        margin={{ top: 5, right: 30, left: 20, bottom: manyLabels ? 35 : 5 }}
      >
        <Pie
          dataKey={columns[1]}
          nameKey={columns[0]}
          cx="50%"
          cy="50%"
          outerRadius="85%"
          fill="#8884d8"
          data={dataWithColors}
          startAngle={90}
          endAngle={450}
        >
          {dataWithColors.map((entry, index) => (
            <Cell key={`pie-chart-cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Legend />
        <Tooltip {...tooltipOptions} />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
}

export default PieChart;
