function KpiStats({
  value,
  name,
}: {
  value: string | number;
  name: string;
  color: string;
}) {
  return (
    <div className="border-l-2 border-primary pl-3">
      <p className="font-semibold text-white whitespace-nowrap">{value}</p>
      <p className="text-secondary-300 text-xs whitespace-nowrap">{name}</p>
    </div>
  );
}

export default KpiStats;
