import { ChartData } from "../../types";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainerProps,
} from "recharts";
import { GRAPH_COLOR_PALETTE } from "../../utils";
import { tooltipOptions } from "./ChartTooltip";

function LineChart({
  data: { data, columns },
  width = "100%",
  height = 300,
}: { data: ChartData } & Pick<ResponsiveContainerProps, "width" | "height">) {
  return (
    <ResponsiveContainer
      width={width}
      height={height}
      style={{
        background: "#32364B",
        padding: "16px 0 16px 0",
        borderRadius: "4px",
      }}
    >
      <RechartsLineChart
        data={data}
        margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
      >
        <CartesianGrid stroke={"#50536D"} />
        <XAxis
          dataKey={columns[0]}
          tick={{ fill: "#C1C3D4" }}
          tickLine={{ stroke: "#C1C3D4" }}
        />
        <YAxis tick={{ fill: "#C1C3D4" }} tickLine={{ stroke: "#C1C3D4" }} />
        <Tooltip {...tooltipOptions} />
        <Legend />
        {columns.slice(1).map((column, index) => (
          <Line
            key={column}
            type="monotone"
            dataKey={column}
            stroke={GRAPH_COLOR_PALETTE[index % GRAPH_COLOR_PALETTE.length]}
            dot={false}
          />
        ))}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
}

export default LineChart;
