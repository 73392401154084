import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { convertToUnits, GRAPH_COLOR_PALETTE } from "../../utils";
import BiGraphCard from "../BiGraphCard";
import { Filters } from "../../openapi";
import { useQuery } from "@tanstack/react-query";
import { prettyPrintEuros } from "./utils";
import Loading from "./Loading";
import { getGrossProfitPerRegionOptions } from "../../openapi/@tanstack/react-query.gen";

function TotalGrossProfitGraph({
  filters,
  tableName,
}: {
  filters: Filters;
  tableName: string;
}) {
  const { data, isLoading } = useQuery({
    ...getGrossProfitPerRegionOptions({
      query: { table_name: tableName },
      body: { filters },
    }),
  });

  if (isLoading) return <Loading className="h-[300px] bg-[#24273F]" />;

  return (
    <BiGraphCard title="Total Gross Profit">
      <div className="flex gap-2 items-end justify-between">
        <div>
          <p className="text-secondary-300 font-semibold mb-2">Regions</p>
          {data?.data.map((e, index) => (
            <div
              key={e.region}
              className="flex gap-2 items-center mb-1 font-light"
            >
              <span
                className="rounded-full w-3 h-3 inline-block"
                style={{
                  background:
                    GRAPH_COLOR_PALETTE[index % GRAPH_COLOR_PALETTE.length],
                }}
              ></span>
              <span className="text-secondary-300">
                {e.region} / € {convertToUnits(e.gross_profit)}{" "}
                {"(" + e.in_percentages + "%)"}
              </span>
            </div>
          ))}
        </div>

        <ResponsiveContainer width="60%" height={300}>
          <PieChart margin={{ top: 0 }}>
            <Pie
              data={data?.data}
              dataKey="gross_profit"
              fill="#1D9DD9"
              nameKey="region"
            >
              {(data?.data ?? []).map((_, index) => (
                <Cell
                  key={index}
                  fill={GRAPH_COLOR_PALETTE[index % GRAPH_COLOR_PALETTE.length]}
                />
              ))}
            </Pie>
            <Tooltip
              formatter={(v: number, name) => {
                const item = data?.data.find((i) => i.region === name);
                return (
                  prettyPrintEuros(v) +
                  " (" +
                  item?.in_percentages?.toString() +
                  "%)"
                );
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </BiGraphCard>
  );
}

export default TotalGrossProfitGraph;
