import { FC } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Dot,
  DotProps,
  Line,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
} from "recharts";
import { RegressionData } from "../../types";

const RenderDot: FC<DotProps> = ({ cx, cy, fill }) => {
  return <Dot cx={cx} cy={cy} fill={fill} r={3} />;
};

export interface RegressionGraphProps {
  xLabel: string;
  yLabel: string;
  data: RegressionData;
  legend: string;
  height?: number;
}

function RegressionGraph({
  height = 400,
  xLabel,
  yLabel,
  data,
  legend,
}: RegressionGraphProps) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        data={data}
        margin={{
          top: 30,
          right: 20,
          bottom: 30,
          left: 40,
        }}
      >
        <text
          x="50%"
          y={10}
          fill="#C1C3D4"
          textAnchor="middle"
          dominantBaseline="central"
        >
          <tspan fontSize="14">{legend}</tspan>
        </text>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="y"
          domain={[
            (dataMin: number) => Math.floor(dataMin * 0.9),
            (dataMax: number) => Math.ceil(dataMax * 1.1),
          ]}
          type="number"
          label={{
            value: xLabel,
            position: "bottom",
            offset: 10,
            fill: "#C1C3D4",
          }}
          tick={{ fill: "#C1C3D4" }}
          tickLine={{ stroke: "#C1C3D4" }}
        />
        <YAxis
          type="number"
          label={{
            value: yLabel,
            angle: -90,
            position: "left",
            align: "center",
            offset: 25,
            style: { textAnchor: "middle" },
            fill: "#C1C3D4",
          }}
          tick={{ fill: "#C1C3D4" }}
          tickLine={{ stroke: "#C1C3D4" }}
        />
        <Scatter
          dataKey="residuals"
          fill="#1D9DD9"
          shape={<RenderDot />}
          isAnimationActive={false}
          legendType="none"
        />
        <Line
          name={legend}
          dataKey="predicted"
          stroke="#FD5468"
          dot={false}
          activeDot={false}
          legendType="none"
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default RegressionGraph;
