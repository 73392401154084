import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import ChartTooltipContent from "./ChartTooltipContent";

export const tooltipOptions: TooltipProps<ValueType, NameType> = {
  contentStyle: {
    backgroundColor: "#32364B",
    borderRadius: "6px",
  },
  labelStyle: {
    color: "#C1C3D4",
    fontSize: "18px",
    fontWeight: "500",
  },
  content: ChartTooltipContent,
};
