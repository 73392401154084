import { Select, SelectItem, SelectProps } from "@nextui-org/react";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { PERIODS, Period } from "../utils";

export function PeriodFilter({
  value,
  onChange,
  className,
}: {
  value: Period | null;
  onChange: (value: Period | null) => void;
  className?: string;
}) {
  return (
    <Select
      className={className}
      variant="flat"
      label="Period"
      color="secondary"
      classNames={{
        label: "text-secondary-400",
        value: "text-secondary-300",
        trigger:
          "bg-transparent data-[hover=true]:bg-transparent border border-secondary-700",
      }}
      labelPlacement="outside"
      selectedKeys={value ? [value] : undefined}
      onChange={(e) => {
        onChange(e.target.value as Period);
      }}
    >
      {PERIODS.map((v) => (
        <SelectItem key={v} value={v}>
          {v}
        </SelectItem>
      ))}
    </Select>
  );
}

const isNumberArray = (v: unknown): v is number[] => {
  return Array.isArray(v) && typeof v[0] === "number";
};

const isStringArray = (v: unknown): v is string[] => {
  return Array.isArray(v) && typeof v[0] === "string";
};

const capitalizeFirst = (v: number | string): string =>
  typeof v === "string"
    ? v.substring(0, 1).toUpperCase() + v.substring(1)
    : v.toString();

type Unpacked<T> = T extends (infer U)[] ? U : T;

export function ChartFilter<T extends number | string | number[] | string[]>({
  value,
  onChange,
  options,
  className,
  labelPlacement = "outside",
  label,
  placeholder,
}: {
  value: T | null | undefined;
  onChange: (value: T) => void;
  options: Unpacked<T>[] | null;
  className?: string;
  labelPlacement?: SelectProps["labelPlacement"] | "placeholder";
  label: string;
  placeholder?: SelectProps["placeholder"];
}) {
  const items = useMemo(
    () => (options ?? []).map((v) => ({ label: v, key: v })),
    [options],
  );
  const selectedKeys = useMemo(
    () =>
      (value ? (Array.isArray(value) ? value : [value]) : []) as
        | number[]
        | string[],
    [value],
  );
  return (
    <Select
      variant="flat"
      aria-label={label}
      label={labelPlacement === "placeholder" ? undefined : label}
      className={className}
      color="secondary"
      classNames={{
        label: "text-secondary-400",
        value: "text-secondary-300",
        trigger:
          "bg-transparent data-[hover=true]:bg-transparent border border-secondary-700",
      }}
      placeholder={labelPlacement === "placeholder" ? label : placeholder}
      labelPlacement={
        labelPlacement === "placeholder" ? undefined : labelPlacement
      }
      selectionMode={Array.isArray(value) ? "multiple" : "single"}
      selectedKeys={selectedKeys}
      onSelectionChange={(v) => {
        if (v === "all") {
          if (Array.isArray(value)) {
            onChange(value as T);
          }
        } else {
          const vArray = Array.from(v);

          if (
            isNumberArray(options) &&
            (Array.isArray(value) || Array.isArray(vArray))
          ) {
            onChange(vArray.map(Number) as T);
          } else if (isStringArray(options) && Array.isArray(value)) {
            onChange(vArray.map((v) => v.toString()) as T);
          } else if (typeof value === "string") {
            onChange(vArray[0] as T);
          } else if (typeof value === "number") {
            onChange(Number(vArray[0]) as T);
          } else {
            console.error("Strange onSelectionChange value: ", vArray);
          }
        }
      }}
      items={items}
    >
      {(item) => <SelectItem>{capitalizeFirst(item.label)}</SelectItem>}
    </Select>
  );
}

export function RegionFilter({
  value,
  onChange,
  options,
  className,
  labelPlacement = "outside",
}: {
  value: string[] | null;
  onChange: (value: (string | number)[]) => void;
  options: string[] | null;
  className?: string;
  labelPlacement?: SelectProps["labelPlacement"] | "placeholder";
}) {
  return (
    <Select
      variant="flat"
      aria-label="Regions"
      label={labelPlacement === "placeholder" ? undefined : "Regions"}
      className={className}
      color="secondary"
      classNames={{
        label: "text-secondary-400",
        value: "text-secondary-300",
        trigger:
          "bg-transparent data-[hover=true]:bg-transparent border border-secondary-700",
      }}
      placeholder={labelPlacement === "placeholder" ? "Regions" : undefined}
      labelPlacement={
        labelPlacement === "placeholder" ? undefined : labelPlacement
      }
      selectionMode="multiple"
      selectedKeys={value ?? []}
      onSelectionChange={(v) => {
        if (v === "all") {
          onChange(value ?? []);
        } else {
          onChange(Array.from(v));
        }
      }}
    >
      {(options ?? []).map((v) => (
        <SelectItem key={v} value={v}>
          {v}
        </SelectItem>
      ))}
    </Select>
  );
}

function BiGraphCard({
  title,
  children,
  headerComponent,
  className,
}: {
  title?: string;
  children: React.ReactNode | React.ReactNode[];
  headerComponent?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge("bg-[#24273F] p-6 rounded flex flex-col", className)}
    >
      <div className="flex items-end mb-4 gap-4">
        {title && (
          <p className="text-[#F8FCFD] text-large flex-auto">{title}</p>
        )}
        {headerComponent}
      </div>
      <div className="bg-secondary-800 px-4 py-6 rounded flex-grow">
        {children}
      </div>
    </div>
  );
}

export default BiGraphCard;
