import { ChartData } from "../../types";
import {
  ResponsiveContainer,
  BarChart as RechartsBarCharts,
  CartesianGrid,
  Bar,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainerProps,
} from "recharts";
import { GRAPH_COLOR_PALETTE } from "../../utils";
import { tooltipOptions } from "./ChartTooltip";

function BarChart({
  data: { data, columns },
  stacked = false,
  width = "100%",
  height = 400,
}: {
  data: ChartData;
  stacked?: boolean;
} & Pick<ResponsiveContainerProps, "width" | "height">) {
  const manyLabels = data.length > 5;

  return (
    <ResponsiveContainer
      width={width}
      height={height}
      style={{
        background: "#32364B",
        borderRadius: "4px",
      }}
    >
      <RechartsBarCharts
        data={data}
        margin={{ top: 20, right: 30, left: 30, bottom: manyLabels ? 35 : 5 }}
      >
        <CartesianGrid stroke={"#50536D"} />
        <XAxis
          angle={manyLabels ? -45 : 0}
          fontSize={14}
          dy={manyLabels ? 20 : 10}
          dataKey={columns[0]}
          tick={{ fill: "#C1C3D4" }}
          tickLine={{ stroke: "#C1C3D4" }}
        />
        <YAxis tick={{ fill: "#C1C3D4" }} tickLine={{ stroke: "#C1C3D4" }} />
        <Tooltip {...tooltipOptions} />
        <Legend verticalAlign="top" wrapperStyle={{ top: -5 }} />

        {columns.slice(1).map((column, index) => (
          <Bar
            key={column}
            dataKey={column}
            stackId={stacked ? "a" : undefined}
            fill={GRAPH_COLOR_PALETTE[index % GRAPH_COLOR_PALETTE.length]}
          />
        ))}
      </RechartsBarCharts>
    </ResponsiveContainer>
  );
}

export default BarChart;
