import { FC } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Dot,
  DotProps,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { AnomalyData } from "../../types";
import dayjs from "dayjs";

const RenderDot: FC<DotProps> = ({ cx, cy, fill }) => {
  return <Dot cx={cx} cy={cy} fill={fill} r={3} />;
};

const dateFormatter = (date: number) => {
  return dayjs.unix(date).format("MMM");
};

export interface AnomalyGraphProps {
  xLabel: string;
  yLabel: string;
  data: AnomalyData;
  height?: number;
}

function AnomalyGraph({
  height = 400,
  xLabel,
  yLabel,
  data,
}: AnomalyGraphProps) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 80,
          bottom: 30,
          left: 40,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <Tooltip
          // formatter={(v, name, item) => {}}
          labelFormatter={(_, p) => {
            const item = p.find((v) => v.name === "line");

            if (!item) return "unknown";
            const payload = { ...item.payload } as Record<
              string,
              string | number
            >;

            if ("point" in payload) {
              delete payload.point;
            }

            if ("line" in payload) {
              delete payload.line;
            }

            if ("dateEpoch" in payload) {
              delete payload.dateEpoch;
            }

            const labels = Object.entries(payload).map(
              ([key, value]) => key + ": " + value + "\n",
            );

            return <span className="whitespace-pre">{labels}</span>;
          }}
        />
        <Legend
          layout="vertical"
          verticalAlign="top"
          align="center"
          wrapperStyle={{
            paddingBottom: "20px",
          }}
        />
        <XAxis
          dataKey="dateEpoch"
          scale="time"
          type="number"
          tickFormatter={dateFormatter}
          tickCount={10}
          domain={[
            "dataMin",
            (dataMax: number) => {
              return dataMax;
            },
          ]}
          label={{
            value: xLabel,
            position: "bottom",
            offset: 10,
          }}
        />
        <YAxis
          type="number"
          label={{
            value: yLabel,
            angle: -90,
            position: "left",
            align: "center",
            offset: 20,
            style: { textAnchor: "middle" },
          }}
          domain={[
            (dataMin: number) => Math.floor(dataMin * 0.9),
            (dataMax: number) => Math.ceil(dataMax * 1.1),
          ]}
        />
        <Scatter
          dataKey="point"
          shape={<RenderDot />}
          isAnimationActive={false}
          legendType="none"
          fill="#FD5468"
        />
        <Line
          dataKey="line"
          fill="#1D9DD9"
          dot={false}
          activeDot={false}
          legendType="none"
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default AnomalyGraph;
