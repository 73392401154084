import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from "recharts";
import { SalesForecastData } from "../../types";
import dayjs from "dayjs";

const dateFormatter = (date: number) => {
  return dayjs.unix(date).format("DD/MM/YY HH:mm");
};

export interface SalesForecastGraphProps {
  xLabel: string;
  yLabel: string;
  data: SalesForecastData;
  height?: number;
}

function SalesForecastGraph({
  height = 400,
  xLabel,
  yLabel,
  data,
}: SalesForecastGraphProps) {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart
        data={data}
        margin={{
          top: 20,
          right: 80,
          bottom: 30,
          left: 40,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <Tooltip
          formatter={(value, name) => {
            if (name === "bound") {
              const [lower, upper] =
                value as SalesForecastData[number]["bound"];
              return "[" + lower.toFixed(2) + ", " + upper.toFixed(2) + "]";
            }

            return (value as SalesForecastData[number]["forecast"]).toFixed(2);
          }}
          labelFormatter={(label: number) => {
            return "Date: " + dateFormatter(label);
          }}
        />
        <Legend
          layout="horizontal"
          verticalAlign="top"
          align="center"
          wrapperStyle={{
            paddingBottom: "20px",
          }}
        />
        <XAxis
          dataKey="dateEpoch"
          scale="time"
          type="number"
          tickFormatter={dateFormatter}
          domain={["dataMin", "dataMax"]}
          label={{
            value: xLabel,
            position: "bottom",
            fill: "#C1C3D4",
            offset: 10,
          }}
          tick={{ fill: "#C1C3D4" }}
          tickLine={{ stroke: "#C1C3D4" }}
        />
        <YAxis
          type="number"
          label={{
            value: yLabel,
            angle: -90,
            position: "left",
            fill: "#C1C3D4",
            align: "center",
            offset: 20,
            style: { textAnchor: "middle" },
          }}
          tick={{ fill: "#C1C3D4" }}
          tickLine={{ stroke: "#C1C3D4" }}
          domain={[
            (dataMin: number) => Math.floor(dataMin * 1.5),
            (dataMax: number) => Math.ceil(dataMax * 1.5),
          ]}
        />
        <Area
          dataKey="bound"
          stroke="#c3e6f7"
          fill="#c3e6f74F"
          legendType="plainline"
        />
        <Line
          dataKey="forecast"
          fill="#1D9DD9"
          strokeWidth={3}
          dot={false}
          activeDot={false}
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default SalesForecastGraph;
